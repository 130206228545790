<template>
	<el-dialog
		:title="titleDialog"
		:visible="showDialog"
		@close="close"
		@open="create"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		width="60%"
	>
		<form autocomplete="off" @submit.prevent="submit">
			<v-row dense>
				<v-col cols="12" xl="6" lg="12" md="12" sm="12">
					<div class="form-group" :class="{ 'has-danger': errors.establishment_id }">
						<label class="control-label">Establecimiento</label>
						<el-select v-model="form.establishment_id">
							<el-option
								v-for="option in establishments"
								:key="option.id"
								:value="option.id"
								:label="option.name"
							></el-option>
						</el-select>
						<small
							class="error--text"
							v-if="errors.establishment_id"
							v-text="errors.establishment_id[0]"
						></small>
					</div>
				</v-col>
				<v-col cols="12" xl="6" lg="12" md="12" sm="12">
					<div class="form-group" :class="{ 'has-danger': errors.document_type_id }">
						<label class="control-label">Tipo comprobante</label>
						<el-select
							v-model="form.document_type_id"
							@change="changeDocumentType"
							popper-class="el-select-document_type"
							dusk="document_type_id"
						>
							<el-option
								v-for="option in document_types"
								:key="option.id"
								:value="option.id"
								:label="option.description"
							></el-option>
						</el-select>
						<small
							class="error--text"
							v-if="errors.document_type_id"
							v-text="errors.document_type_id[0]"
						></small>
					</div>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="12" xl="6" lg="12" md="12" sm="12">
					<div class="form-group" :class="{ 'has-danger': errors.series_id }">
						<label class="control-label">Serie</label>
						<el-select v-model="form.series_id" dusk="series_id" @change="changeSeries">
							<el-option
								v-for="option in series"
								:key="option.id"
								:value="option.id"
								:label="option.number"
							></el-option>
						</el-select>
						<small
							class="error--text"
							v-if="errors.series_id"
							v-text="errors.series_id[0]"
						></small>
					</div>
				</v-col>
				<v-col cols="12" xl="6" lg="12" md="12" sm="12">
					<div class="form-group" :class="{ 'has-danger': errors.number }">
						<label class="control-label">Número</label>
						<el-input v-model="form.number"></el-input>
						<small
							class="error--text"
							v-if="errors.number"
							v-text="errors.number[0]"
						></small>
					</div>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" xl="12" lg="12" md="12" sm="12">
					<el-button type="danger" @click.prevent="close()">Cancelar</el-button>
					<el-button type="primary" native-type="submit" :loading="loading">
						<template v-if="loading">
							Guardando...
						</template>
						<template v-else>
							Guardar
						</template>
					</el-button>
				</v-col>
			</v-row>
		</form>
	</el-dialog>
</template>

<script>
import { confirm } from 'Mixins/confirm';
import filter from 'lodash/filter';

export default {
	props: ['showDialog'],
	mixins: [confirm],
	data() {
		return {
			loading: false,
			titleDialog: null,
			resource: 'correlatives',
			errors: {},
			form: {},
			users: [],
			establishments: [],
			document_types: [],
			all_series: [],
			series: [],
		};
	},
	methods: {
		getTables() {
			this.$http.get(`/${this.resource}/tables`).then((response) => {
				this.establishments = response.data.establishments;
				this.document_types = response.data.document_types;
				this.all_series = response.data.series;
			});
		},
		initForm() {
			this.errors = {};
			this.form = {
				id: null,
				establishment_id: null,
				document_type_id: null,
				series_id: null,
				series: null,
				number: null,
			};
		},
		async create() {
			this.titleDialog = 'Nuevo correlativo';
			await this.initForm();
			await this.getTables();
		},
		changeDocumentType() {
			this.form.series_id = null;
			this.series = filter(this.all_series, {
				establishment_id: this.form.establishment_id,
				document_type_id: this.form.document_type_id,
			});
			this.form.series_id = this.series.length > 0 ? this.series[0].id : null;
			this.form.series = this.series.length > 0 ? this.series[0].number : null;
		},
		changeSeries() {
			this.form.series = null;
			let series = filter(this.series, { id: this.form.series_id });
			this.form.series = series.number;
		},
		submit() {
			this.confirm().then(() => {
				this.loading = true;
				this.$http
					.post(`/${this.resource}`, this.form)
					.then((response) => {
						if (response.data.success) {
							this.$message.success(response.data.message);
							this.$eventHub.$emit('reloadData', this.resource);
							this.close();
						} else {
							this.$message.error(response.data.message);
						}
					})
					.catch((error) => {
						if (error.response.status === 422) {
							this.errors = error.response.data.errors;
						} else {
							this.$message.error(error.response.data.message);
						}
					})
					.then(() => {
						this.loading = false;
					});
			});
		},
		close() {
			this.$emit('update:showDialog', false);
			this.initForm();
		},
	},
};
</script>
